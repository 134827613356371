import Head from "next/head"
import {withTRPC} from "@trpc/next"
import "@/styles/style.css"
import {AppProps} from "next/app"
import {ClientContext} from "@/lib/hooks/clientContext"

function MyApp(props: AppProps): JSX.Element {
  return <>
    <Head>
        <meta name="facebook-domain-verification" content={process.env.NEXT_PUBLIC_META_DOMAIN_VERFICATION_ID}/>
        <script 
          src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_TRACKING_ID}`} 
          defer
        />
        <script 
          src="/analytics.js" 
          app-google-analytics-id={process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_TRACKING_ID} 
          defer
        /> 
        <script
            src="/meta-pixel.js"
            app-meta-pixel-id={process.env.NEXT_PUBLIC_META_PIXEL_ID}
            defer
        />
    </Head>
    
    <ClientContext.Provider value={{}}>
      <props.Component {...props.pageProps} />
    </ClientContext.Provider>
  </>
}

export default withTRPC({
  config: () => ({url: "/api/trpc"}),
  ssr: false,
})(MyApp)
