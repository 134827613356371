import {createContext, useContext} from "react"

type Context = {}

export const ClientContext = createContext<Context>(
    null as unknown as Context
)

export function useClientContext() {
    return useContext(ClientContext)
}
